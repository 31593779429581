import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { media } from '../theme'

import Layout from '../components/Layout'
import Block from '../components/Block'

const StyledBlock = styled(Block)`
  text-align: left;
  padding-top: ${rem(64)};
  ${media('sm')`
    padding-top: ${rem(84)};
  `}
`

const Title = styled.h1`
  line-height: 1.3;
  margin: 0;
  margin-bottom: ${rem(8)};
`

const Subtitle = styled.h2`
  font-size: ${rem(24)};
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.3;
  margin-top: ${rem(24)};
  margin-bottom: ${rem(8)};
`

const List = styled.ul`
  padding-left: ${rem(18)};
`

const ListItem = styled.li`
  margin-bottom: ${rem(8)};
`

const CookiesPage = () => (
  <Layout>
    <StyledBlock>
      <Title>Om cookies</Title>
      <p>Montaro använder så kallade cookies på sin webbsida.</p>
      <Subtitle>Vad är cookies?</Subtitle>
      <p>
        Cookies är små textfiler som lagras på besökarens dator och som
        innehåller data från webbplatser som besökts. Nästa gång besökaren
        kommer till samma webbplats kan den läsa besökarens cookies och visa
        sidorna enligt rätt inställningar.
      </p>
      <p>
        Du kan stänga av cookies via säkerhetsinställningarna i din webbläsare.
      </p>
      <Subtitle>Hur använder vi cookies?</Subtitle>
      <p>
        Vi använder cookies för att samla statistik och analysera användandet av
        vår webbplats. Informationen rör antal sidvisningar, var besökare kommer
        ifrån, etc.
      </p>
      <p>
        I vissa fall är dessa tredjepartscookies som rapporterar data till
        samarbetspartners till Montaro.
      </p>
      <Subtitle>För statistik</Subtitle>
      <List>
        <ListItem>
          <a
            href="https://marketingplatform.google.com/about/analytics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Analytics
          </a>
        </ListItem>
      </List>
    </StyledBlock>
  </Layout>
)

export default CookiesPage
